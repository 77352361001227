export const USER = "USER";
export const ANON = "ANON";

export const AUTH_SERVICE_DETAILS_KEY = "QVVUSF9TRVJWSUNFX0RFVEFJTFNfS0VZ";

export class AuthService {
  static Role = ANON;

  static Details = null;

  static setDetails = data => {
    localStorage.setItem(AUTH_SERVICE_DETAILS_KEY, JSON.stringify(data));
    AuthService.Details = data;
    AuthService.Role = USER;
  };

  static getDetails = () => {
    AuthService.Details = localStorage.getItem(AUTH_SERVICE_DETAILS_KEY);
    if (AuthService.Details) {
      AuthService.Details = JSON.parse(AuthService.Details);
      AuthService.Role = USER;
    }

    return AuthService.Details;
  };

  static logout = () => {
    AuthService.Details = null;
    AuthService.Role = ANON;
    localStorage.removeItem(AUTH_SERVICE_DETAILS_KEY);
  };
}
