import { USER, ANON } from "../services/auth";
import { lazy } from "react";

export default [
  {
    path: ["/", "/home"],
    role: [USER],
    component: lazy(() => import("../views/Home/Home"))
  },
  {
    path: ["/", "/sign-in"],
    role: [ANON],
    component: lazy(() => import("../views/SignIn/SignIn"))
  },
  {
    path: ["/alice/sign-in"],
    role: [ANON],
    component: lazy(() => import("../views/SignInAlice/SignIn"))
  },
  {
    path: ["/sign-up"],
    role: [ANON],
    component: lazy(() => import("../views/SignUp/SignUp"))
  },
  {
    path: ["/setup"],
    role: [ANON, USER],
    component: lazy(() => import("../views/Setup/Setup"))
  },
  {
    path: ["/terms"],
    role: [ANON, USER],
    component: lazy(() => import("../views/Terms/Terms"))
  },
  {
    path: ["/confirm"],
    role: [ANON],
    component: lazy(() => import("../views/Confirm/Confirm"))
  },
  {
    path: ["/restore"],
    role: [ANON],
    component: lazy(() => import("../views/Restore/Restore"))
  },
  {
    path: ["/profile"],
    role: [USER],
    component: lazy(() => import("../views/Profile/Profile"))
  },
  {
    path: ["/status"],
    role: [USER],
    component: lazy(() => import("../views/Status/Status"))
  }
];
