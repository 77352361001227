import React, { Component, Suspense } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthService } from "../../services/auth";
import { ping } from "../../api/controller";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Routes from "../../routes";

import "./App.css";
import Header from "../Header/Header";
import Spinner from "../Spinner/Spinner";
import Footer from "../Footer/Footer";
import Modal from "../Modal/Modal";
import Survey from "../../views/Survey/Survey";

toast.configure({
  autoClose: 3000,
  draggable: true
});

let interval = null;

export let updateRoutes = function() {
  this.forceUpdate();
};

export let updateSpinner = function(state) {
  this.setState({ spinner: state });
};

export const showSpinner = () => {
  updateSpinner(true);
};

export const hideSpinner = () => {
  updateSpinner(false);
};

const getWarning = () => {
  if (localStorage.getItem("WARNING") !== null) {
    return JSON.parse(localStorage.getItem("WARNING"));
  }
  return true;
};

export class App extends Component {
  state = {
    routes: Routes,
    spinner: false,
    warning: getWarning()
  };

  constructor(props) {
    super(props);
    updateRoutes = updateRoutes.bind(this);
    updateSpinner = updateSpinner.bind(this);
    window.addEventListener(
      "message",
      function(e) {
        var key = e.message ? "message" : "data";
        var data = e[key];
        try {
          if (!data || data.includes("mc.yandex")) return

          if (data.includes("/")) {
            window.location.pathname = data;
          } else if (data.startsWith("http")) {
            window.location.href = data;
          }
        } catch { }
      },
      false
    );

    clearInterval(interval);
    sessionStorage.removeItem("CONTROLLER_STATUS");
    this.ping();
  }

  async pingRequest() {
    const isOnline = await ping();

    if (isOnline) {
      sessionStorage.setItem("CONTROLLER_STATUS", "Онлайн");
    } else {
      sessionStorage.setItem("CONTROLLER_STATUS", "Оффлайн");
    }
  }

  async ping() {
    await this.pingRequest();
    interval = setInterval(async () => {
      await this.pingRequest();
    }, 1 * 60 * 1000);
  }

  renderRoutes() {
    const Role = AuthService.Role;

    return this.state.routes
      .filter(route => route.role.includes(Role))
      .map((route, index) => {
        return route.path.map(path => (
          <Route
            key={index + path}
            exact={path === "/"}
            path={path}
            component={route.component}
          />
        ));
      })
      .flat();
  }

  onModalClick = () => {
    this.setState({ warning: false });
    localStorage.setItem("WARNING", JSON.stringify(false));
  };

  render() {
    const isHome = window.location.pathname.includes("/home");
    const isSurvey = window.location.pathname.includes("/survey");
    const isAliceSignIn = window.location.pathname === '/alice/sign-in';

    return (
      <Router>
        <Switch>
          <Suspense fallback={<Spinner />}>
            <Header />
            {isHome ? (
              <iframe
                src={"/landing/" + window.location.hash}
                className="main-iframe"
                title="main-iframe"
              />
            ) : (
              <>
                <div id="wrapper">{this.renderRoutes()}</div>
                <ToastContainer />
                <Spinner show={this.state.spinner} />
                {!isSurvey && <Footer />}
                {isSurvey && <Survey />}
                {!isAliceSignIn && <Modal show={this.state.warning} onClick={this.onModalClick}/>}
              </>
            )}
          </Suspense>
        </Switch>
      </Router>
    );
  }
}

export default App;
