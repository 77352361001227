import React, { Component } from "react";
import "./Spinner.css";

export default class Spinner extends Component {
  render() {
    const show = this.props.show === undefined ? true : this.props.show;
    
    return (
      <div className="spinner" style={{ display: show ? "block" : "none" }}>
        <div style={{ maxWidth: 300, maxHeight: 300, margin: "25% auto" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-zigzag"
          >
            <defs>
              <clipPath id="zigzag-clip-d9d3c6925dfba">
                <rect x="20" y="0" width="60" height="100" />
              </clipPath>
            </defs>
            <path
              fill="none"
              stroke="#fdfdfd"
              strokeWidth="5"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              clipPath="url(#zigzag-clip-d9d3c6925dfba)"
              d="M90,76.7V28.3c0-2.7-2.2-5-5-5h-3.4c-2.7,0-5,2.2-5,5v43.4c0,2.7-2.2,5-5,5h-3.4c-2.7,0-5-2.2-5-5V28.3c0-2.7-2.2-5-5-5H55 c-2.7,0-5,2.2-5,5v43.4c0,2.7-2.2,5-5,5h-3.4c-2.7,0-5-2.2-5-5V28.3c0-2.7-2.2-5-5-5h-3.4c-2.7,0-5,2.2-5,5v43.4c0,2.7-2.2,5-5,5H15 c-2.7,0-5-2.2-5-5V23.3"
            >
              <animateTransform
                attributeName="transform"
                type="translate"
                calcMode="linear"
                values="-20 0;7 0"
                keyTimes="0;1"
                dur="1s"
                begin="0s"
                repeatCount="indefinite"
              />
              <animate
                attributeName="stroke-dasharray"
                calcMode="linear"
                values="0 72 125 232;0 197 125 233"
                keyTimes="0;1"
                dur="1"
                begin="0s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      </div>
    );
  }
}
