import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';
import { AuthService } from './services/auth';
import * as serviceWorker from './serviceWorker';

AuthService.getDetails();

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();