import React, { Component } from "react";
import "./Footer.css";

export default class Footer extends Component {
  render() {
    return (
      <footer>
        Связаться с нами: <a href="mailto:info@g-on.io">info@g-on.io</a>
      </footer>
    );
  }
}
