import React, { Component } from "react";
import "./Modal.css";

export default class Modal extends Component {
  onModalClick = () => {
    this.props.onClick();
  };

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <div className="Modal" onClick={this.onModalClick}>
        <div className="Modal-container">
          <div className="Modal-body">
            Наш сервис homebridge.g-on.io находится в стадии открытого бета
            тестирования. Мы будем рады услышать ваши впечатления от
            использования. Сейчас сервис предоставляется бесплатно. Вместе с
            окончательным релизом мы планируем добавить платный функционал,
            чтобы поддерживать дальнейшую разработку, исправление ошибок и новые
            интеграции.
          </div>
          <div className="Modal-footer">
            <button className="btn btn-success btn-blocko">Продолжить</button>
          </div>
        </div>
      </div>
    );
  }
}
