import React, { Component } from "react";

import logo from "../../assets/logo.png";

import "./Header.css";
import { AuthService, ANON, USER } from "../../services/auth";
import { updateRoutes } from "../App/App";

export default class Header extends Component {
  render() {
    const role = AuthService.Role;

    return (
      <section id="sidebar">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="inner">
          <nav>
            <ul>
              {role === USER && (
                <li>
                  <a href="/">Профиль</a>
                </li>
              )}
              {role === USER && (
                <li>
                  <a href="/status">Статус</a>
                </li>
              )}
              <li>
                <a href="/home/main#intro">Главная</a>
              </li>
              <li className="hide-nav">
                <a href="/home/how#one">Как это работает</a>
              </li>
              <li className="hide-nav">
                <a href="/home/about#two">О нас</a>
              </li>
              <li className="hide-nav">
                <a href="/home/contact#four">Связь</a>
              </li>
              {role === ANON && (
                <li>
                  <a href="/sign-in">Вход</a>
                </li>
              )}
              {role === ANON && (
                <li>
                  <a href="/sign-up">Регистрация</a>
                </li>
              )}
              <li>
                <a href="/setup">Настройка</a>
              </li>

              {role === USER && (
                <li>
                  <a
                    href="/"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      AuthService.logout();
                      updateRoutes();
                    }}
                  >
                    Выйти
                  </a>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </section>
    );
  }
}
