import React, { Component } from "react";
import { showSpinner, hideSpinner } from "../../components/App/App";

const PORTAL_ID = "6102314";
const FORM_ID = "2e7ac0da-486e-468a-bfe5-297c1ac63600";

export class Survey extends Component {
  componentDidMount() {
    showSpinner();
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/shell.js";

    const container = document.getElementById("Survey");

    setTimeout(() => {
      // eslint-disable-next-line
      eval(
        `
        if (hbspt) {
          hbspt.forms.create({portalId: "${PORTAL_ID}", formId: "${FORM_ID}" });
        } else {
          location.reload();
        }
        `
      );
      hideSpinner();
    }, 1000);
    container.appendChild(script);
  }

  render() {
    return <div id="Survey" />;
  }
}

export default Survey;
