import { http } from "./http";
import { AuthService } from "../services/auth";

export const ping = async () => {
  if (!AuthService.Details) {
    return null;
  }

  try {
    await http.get("/v1.0/user/devices", {
      headers: {
        Authorization: `Bearer ${AuthService.Details.access_token}`
      }
    });

    await http.post(
      "/user/active",
      { isActive: true },
      {
        headers: {
          Authorization: `Bearer ${AuthService.Details.access_token}`
        }
      }
    );
    return true;
  } catch(err) {
    await http.post(
      "/user/active",
      { isActive: false },
      {
        headers: {
          Authorization: `Bearer ${AuthService.Details.access_token}`
        }
      }
    );
    return false;
  }
};
